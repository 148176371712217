<template>
  <a-modal title="添加群组" :visible="visible" :width="720" @ok="ok" @cancel="reset">
    <a-form-model
      ref="form"
      :label-col="{ span: 4, style: { textAlign: 'left' } }"
      :model="form"
      :rules="rules"
      :wrapper-col="{ span: 20 }"
    >
      <a-form-model-item label="群组名称" prop="name">
        <a-input v-model="form.name" :max-length="128" placeholder="请输入群组名称"></a-input>
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
export default {
  name: 'GroupForm',
  data () {
    return {
      visible: false,
      form: {
        name: ''
      },
      rules: {
        name: [{ message: '请输入群组名称', required: true, trigger: 'blur' }]
      },
      node: {},
      button: {
        height: 14,
        width: 16,
        rx: 2,
        ry: 2,
        fill: '#00327c9e',
        cursor: 'pointer',
        event: 'node:collapse'
      },
      buttonSign: {
        refX: 3,
        refY: 2,
        stroke: 'rgba(255,255,255,.7)'
      }
    }
  },
  methods: {
    show (node) {
      this.node = node
      this.form.name = node.store.data.attrs.label.text
      this.visible = true
    },
    reset () {
      this.$refs.form.resetFields()
      this.visible = false
    },
    ok () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.node.attr('label/text', this.form.name)
          this.node.attr('button', this.button)
          this.node.attr('buttonSign', this.buttonSign)
          this.node.resize(200, 100)
          this.$emit('ok', this.node)
          this.reset()
        }
      })
    }
  }
}
</script>
