<template>
  <a-modal :title="`添加${title}`" :visible="visible" :width="1080" @ok="ok" @cancel="cancel">
    <a-form-model ref="form" :model="form">
      <a-row :gutter="16">
        <a-col span="6">
          <a-form-model-item prop="name">
            <a-input v-model="form.name" :allow-clear="true" :max-length="128" :placeholder="`请输入${title}名称`"></a-input>
          </a-form-model-item>
        </a-col>
        <a-col v-if="node.data.sourceType === 'host'" span="6">
          <a-form-model-item prop="monitorAddress">
            <a-input v-model="form.monitorAddress" :allow-clear="true" :max-length="15" placeholder="请输入主机监控地址"></a-input>
          </a-form-model-item>
        </a-col>
        <a-col span="6">
          <a-form-model-item>
            <a-button icon="search" type="primary" @click="() => { this.form.page = 1; this.fetch() }">查询</a-button>
          </a-form-model-item>
        </a-col>
      </a-row>
    </a-form-model>

    <a-table
      :columns="columns"
      :data-source="dataSource"
      :loading="loading"
      :pagination="false"
      row-key="id"
      :row-selection="rowSelection"
      size="small"
      style="margin-bottom: 16px;"
    >
      <template slot="name" slot-scope="record">
        <a-tooltip placement="topLeft" :title="record">{{ record }}</a-tooltip>
      </template>
      <template slot="active" slot-scope="record">
        <boolean-status-badge :value="record"></boolean-status-badge>
      </template>
    </a-table>
    <div style="height: 32px;">
      <a-pagination
        v-model="form.page"
        :pageSize="form.pageSize"
        :show-size-changer="true"
        :show-total="t => `共 ${t} 条`"
        size="small"
        :total="total"
        @change="page => { this.form.page = page; this.fetch() }"
        @showSizeChange="(_, pageSize) => { this.form.page = 1; this.form.pageSize = pageSize; this.fetch() }"
        style="float: right;"
      ></a-pagination>
    </div>
  </a-modal>
</template>

<script>
import BooleanStatusBadge from '@/components/badge/BooleanStatusBadge'
import { getSourceListFunc } from '@/utils'

export default {
  name: 'SourceSelectForm',
  components: { BooleanStatusBadge },
  props: {
    projectId: { type: String }
  },
  data () {
    return {
      visible: false,
      form: {
        name: '',
        monitorAddress: '',
        page: 1,
        pageSize: 10
      },
      dataSource: [],
      total: 0,
      loading: false,
      selectedData: [],
      selectedRows: [],
      selectedRowKeys: [],
      rowSelection: {
        onChange: (selectedRowKeys, selectedRows) => {
          this.selectedRowKeys = selectedRowKeys
          this.selectedRows = selectedRows
          if (this.selectedData.length) {
            this.selectedData = this.MergeArray(this.selectedData, this.selectedRows)
          } else {
            this.selectedData = selectedRows
          }
        }
      },
      node: {
        data: {
          iconName: 'host',
          sourceType: 'host'
        }
      }
    }
  },
  computed: {
    title () {
      return this.$t(`source_type.${this.node.data.sourceType}`)
    },
    columns () {
      const columns = [{ dataIndex: 'name', title: '名称', width: 200, ellipsis: true, scopedSlots: { customRender: 'name' } }]
      if (this.node.data.sourceType === 'host') {
        columns.push({ dataIndex: 'monitor_address', title: '监控地址', width: 160 })
      }
      columns.push({ dataIndex: 'active', title: 'Ping', width: 200, scopedSlots: { customRender: 'active' } })
      return columns
    },
    sourceListFunc () {
      return getSourceListFunc(this.node.data.sourceType)
    }
  },
  methods: {
    show (node) {
      this.node = node
      this.fetch()
      this.visible = true
    },
    fetch () {
      const params = {
        page: this.form.page,
        page_size: this.form.pageSize
      }
      if (this.projectId) params.project_id = this.projectId
      if (this.form.name) params.name = this.form.name
      if (this.form.monitorAddress) params.monitor_address = this.form.monitorAddress
      switch (this.node.data.sourceType) {
        case 'middleware':
          params.middleware_type = this.node.data.iconName
          break
        case 'database':
          params.database_type = this.node.data.iconName
          break
        default:
          params.os_type = this.node.data.iconName
          break
      }
      this.loading = true
      this.sourceListFunc(params).then(res => {
        const data = res.data
        this.dataSource = data.data
        this.total = data.total
      }).finally(() => {
        this.loading = false
      })
    },
    reset () {
      this.dataSource = []
      this.total = 0
      this.selectedRows = []
      this.selectedData = []
      this.selectedRowKeys = []
    },
    ok () {
      const nodes = []
      // 选择节点
      for (let i = 0; i < this.selectedData.length; i++) {
        const row = this.selectedData[i]
        const node = JSON.parse(JSON.stringify(this.node))
        const { x, y } = node.position
        node.data.name = row.name
        node.data.sourceId = row.id
        node.id = row.id
        node.position = { x: x + 10 * i, y: y + 10 * i }
        nodes.push(node)
      }
      this.$emit('ok', nodes)
      this.reset()
      this.visible = false
    },
    cancel () {
      this.reset()
      this.visible = false
    },
    // 去重
    MergeArray (arr1, arr2) {
      let arr = [...arr1]
      for (const item of arr2) {
        if (arr1.findIndex(i => i.id === item.id) === -1) {
          arr.push(item)
        }
      }
      arr = this.SpliceArray(arr, this.selectedRowKeys)
      return arr
    },
    // 删除未选择数据
    SpliceArray (arr1, arr2) {
      const arr = []
      for (const item of arr1) {
        if (arr2.findIndex(i => i === item.id) !== -1) {
          arr.push(item)
        }
      }
      return arr
    }
  }
}
</script>
