<template>
  <a-drawer title="节点详情" :visible="visible" :width="480" @close="reset">
    <a-form-model :label-col="{ span: 4, style: { textAlign: 'left' } }" :wrapper-col="{ span: 20 }">
      <a-form-model-item label="节点名称">
        <a-input v-model="node.data.name" :disabled="true"></a-input>
      </a-form-model-item>
      <a-form-model-item label="监控项">
        <!-- 下拉框自定义状态   -->
        <a-select
          :value="selectedOptions"
          :allow-clear="true"
          :label-in-value="true"
          :max-tag-count="5"
          mode="multiple"
          :options="options"
          placeholder="请选择监控项"
          :show-search="true"
          @change="handleChange"
          @popupScroll="scroll"
        ></a-select>
      </a-form-model-item>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getSourceMonitorListFunc } from '@/utils'

export default {
  name: 'SourceDrawer',
  data () {
    return {
      visible: false,
      options: [],
      selectedOptions: [],
      params: { page: 1, page_size: 10 },
      total: 0,
      node: {
        data: {
          sourceId: '',
          sourceType: '',
          iconName: '',
          monitorItems: [] // 保存自定义状态属性
        }
      }
    }
  },
  methods: {
    fetch () {
      const monitorListFunc = getSourceMonitorListFunc(this.node.data.sourceType)
      monitorListFunc(this.node.data.sourceId, this.params).then(res => {
        const data = res.data
        data.data.forEach(item => {
          this.options.push({ label: item.name, value: item.key })
        })
        this.total = data.total
      })
    },
    show (node) {
      this.node = node
      this.selectedOptions = this.node.data.monitorItems
      this.fetch()
      this.visible = true
    },
    handleChange (value) {
      // 保存到节点列表中
      this.selectedOptions.splice(0, this.selectedOptions.length, ...value)
      this.node.data.monitorItems = this.selectedOptions
    },
    reset () {
      this.params.page = 1
      this.options = []
      this.selectedOptions = []
      this.visible = false
    },
    scroll (e) {
      const { scrollHeight, scrollTop, clientHeight } = e.target
      if ((scrollHeight - 20) <= scrollTop + clientHeight && this.options.length < this.total) {
        this.params.page++
        this.fetch()
      }
    }
  }
}
</script>
