var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-layout',[_c('a-layout-header',{staticStyle:{"background":"#e9e9e9","height":"32px","padding":"0 16px","line-height":"32px","border-bottom":"1px solid #bfbfbf"}},[_c('div',{staticStyle:{"user-select":"none"}},[_c('a-space',{attrs:{"size":"large"}},[_c('a-tooltip',{attrs:{"title":"返回"}},[_c('a',{staticStyle:{"font-size":"16px"},on:{"click":_vm.goBack}},[_c('a-icon',{attrs:{"type":"arrow-left"}})],1)]),(_vm.permission.update)?_c('div',[_c('a-dropdown',{staticStyle:{"user-select":"none"}},[_c('a-space',[_c('a-icon',{attrs:{"type":"file"}}),_c('span',[_vm._v("文件")])],1),_c('a-menu',{attrs:{"slot":"overlay"},on:{"click":function (ref) {
	var _ = ref._;
	var key = ref.key;

	return _vm.click(key);
}},slot:"overlay"},[_c('a-menu-item',{key:"save"},[_c('a-space',[_c('a-icon',{attrs:{"type":"save"}}),_c('span',[_vm._v("保存")])],1)],1)],1)],1),_c('a-divider',{staticStyle:{"background":"#b3b3b3"},attrs:{"type":"vertical"}}),_c('a-dropdown',{staticStyle:{"user-select":"none"}},[_c('a-space',[_c('a-icon',{attrs:{"type":"edit"}}),_c('span',[_vm._v("编辑")])],1),_c('a-menu',{attrs:{"slot":"overlay"},on:{"click":function (ref) {
	var _ = ref._;
	var key = ref.key;

	return _vm.click(key);
}},slot:"overlay"},[_c('a-menu-item',{key:"undo",attrs:{"disabled":!_vm.canUndo}},[_c('a-space',[_c('a-icon',{attrs:{"type":"undo"}}),_c('span',[_vm._v("撤销")])],1)],1),_c('a-menu-item',{key:"redo",attrs:{"disabled":!_vm.canRedo}},[_c('a-space',[_c('a-icon',{attrs:{"type":"redo"}}),_c('span',[_vm._v("重做")])],1)],1),_c('a-menu-divider'),_c('a-menu-item',{key:"clear"},[_c('a-space',[_c('a-icon',{attrs:{"type":"delete"}}),_c('span',[_vm._v("清空")])],1)],1)],1)],1)],1):_vm._e()],1)],1)]),_c('a-layout',{staticStyle:{"background":"#ffffff","min-height":"calc(100vh - 32px)"}},[_c('a-layout-sider',[_c('div',{attrs:{"id":"stencil"}})]),_c('a-layout-content',[_c('div',{staticStyle:{"min-height":"calc(100vh - 32px)"},attrs:{"id":"container"}})])],1)],1),_c('group-form',{ref:"groupForm",on:{"ok":function (node) { this$1.graph.addNode(node) }}}),_c('source-select-form',{ref:"sourceSelectForm",attrs:{"project-id":_vm.$route.params.id},on:{"ok":function (nodes) { this$1.graph.addNodes(nodes) }}}),_c('source-drawer',{ref:"sourceDrawer"}),_c('text-form',{ref:"textForm",on:{"ok":function (node) { this$1.graph.addNode(node) }}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }