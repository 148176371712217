<template>
  <div>
    <!-- 拓扑图界面 -->
    <a-layout>
      <!-- 操作栏 -->
      <a-layout-header style="background: #e9e9e9; height: 32px; padding: 0 16px; line-height: 32px; border-bottom: 1px solid #bfbfbf;">
        <div style="user-select: none;">
          <a-space size="large">
            <a-tooltip title="返回">
              <a @click="goBack"
                 style="font-size: 16px;">
                <a-icon type="arrow-left"></a-icon>
              </a>
            </a-tooltip>
            <div v-if="permission.update">
              <a-dropdown style="user-select: none;">
                <a-space>
                  <a-icon type="file"></a-icon>
                  <span>文件</span>
                </a-space>

                <a-menu slot="overlay"
                        @click="({_, key}) => click(key)">
                  <a-menu-item key="save">
                    <a-space>
                      <a-icon type="save"></a-icon>
                      <span>保存</span>
                    </a-space>
                  </a-menu-item>
                </a-menu>
              </a-dropdown>
              <a-divider type="vertical"
                         style="background: #b3b3b3;"></a-divider>
              <a-dropdown style="user-select: none;">
                <a-space>
                  <a-icon type="edit"></a-icon>
                  <span>编辑</span>
                </a-space>

                <a-menu slot="overlay"
                        @click="({_, key}) => click(key)">
                  <a-menu-item :disabled="!canUndo"
                               key="undo">
                    <a-space>
                      <a-icon type="undo"></a-icon>
                      <span>撤销</span>
                    </a-space>
                  </a-menu-item>
                  <a-menu-item :disabled="!canRedo"
                               key="redo">
                    <a-space>
                      <a-icon type="redo"></a-icon>
                      <span>重做</span>
                    </a-space>
                  </a-menu-item>
                  <a-menu-divider></a-menu-divider>
                  <a-menu-item key="clear">
                    <a-space>
                      <a-icon type="delete"></a-icon>
                      <span>清空</span>
                    </a-space>
                  </a-menu-item>
                </a-menu>
              </a-dropdown>
            </div>
          </a-space>
        </div>
      </a-layout-header>

      <a-layout style="background: #ffffff; min-height: calc(100vh - 32px);">
        <a-layout-sider>
          <div id="stencil"></div>
        </a-layout-sider>
        <!-- 图表 -->
        <a-layout-content>
          <div id="container"
               style="min-height: calc(100vh - 32px);"></div>
        </a-layout-content>
      </a-layout>
    </a-layout>

    <!-- 群组表单 -->
    <group-form ref="groupForm"
                @ok="node => { this.graph.addNode(node) }"></group-form>
    <!-- 选择节点表单 -->
    <source-select-form ref="sourceSelectForm"
                        :project-id="$route.params.id"
                        @ok="nodes => { this.graph.addNodes(nodes) }"></source-select-form>
    <!-- 节点详情抽屉 -->
    <source-drawer ref="sourceDrawer"></source-drawer>
    <!-- 文本节点编辑 -->
    <text-form ref="textForm"
               @ok="node => { this.graph.addNode(node) }"></text-form>
  </div>
</template>

<script>
import { Addon, Graph, Shape } from '@antv/x6'
import { getProjectTopology, updateProject } from '@/api/project'
import { getSourceNodeList, Group } from '@/utils/topology'
import { hasPermission } from '@/utils'
import GroupForm from './modules/GroupForm'
import SourceSelectForm from './modules/SourceSelectForm'
import SourceDrawer from './modules/SourceDrawer'
import TextForm from './modules/TextForm'

export default {
  name: 'ProjectTopology',
  components: {
    GroupForm,
    SourceSelectForm,
    SourceDrawer,
    TextForm
  },
  data () {
    return {
      graph: null,
      history: null,
      canUndo: false,
      canRedo: false,
      stencil: null,
      detail: {
        project: {}
      }
    }
  },
  computed: {
    permission () {
      return {
        update: hasPermission('topology.update')
      }
    }
  },
  mounted () {
    this.initGraph()
    this.initStencil()
    this.fetch()
  },
  methods: {
    initGraph () {
      const container = document.getElementById('container')
      this.graph = new Graph({
        container,
        grid: {
          enable: true,
          type: 'mesh',
          visible: true,
          args: { thickness: 1 }
        },
        history: true,
        interacting: this.permission.update,
        snapline: true, // 对齐线
        selecting: {
          enabled: true,
          rubberband: true, // 框选
          showNodeSelectionBox: true
        },
        mousewheel: {
          enabled: true,
          modifiers: 'ctrl',
          minScale: 0.5,
          maxScale: 4
        },
        embedding: {
          enabled: true,
          findParent ({ node }) {
            return this.getNodes().filter((n) => {
              const data = n.getData()
              if (data && data.parent && !n.collapsed) {
                const targetBBox = n.getBBox()
                return node.getBBox().isIntersectWithRect(targetBBox)
              }
              return false
            })
          }
        },
        highlighting: {
          // 节点可以被嵌入时高亮
          embedding: {
            name: 'stroke',
            args: {
              padding: 1,
              attrs: { stroke: '#73d13d' }
            }
          }
        },
        connecting: {
          snap: true, // 自动吸附
          allowBlank: false, // 是否允许链接在画布的空白处
          allowMulti: 'withPort', // 在起始和终止只能有一个边
          allowLoop: false,
          allowNode: false,
          router: 'manhattan',
          connector: 'rounded',
          createEdge () {
            return new Shape.Edge({
              attrs: {
                line: {
                  stroke: '#1890ff',
                  strokeDasharray: 5,
                  targetMarker: 'classic',
                  style: { animation: 'topology-edge 60s infinite linear' }
                }
              }
            })
          }
        },
        scroller: true,
        resizing: {
          allowReverse: false,
          minHeight: 36,
          minWidth: 36,
          enabled: (node) => {
            return node.data.parent || node.data.isText
          }
        },
        keyboard: true
      })
      if (this.permission.update) {
        this.history = this.graph.history
        this.history.on('change', () => {
          this.canUndo = this.history.canUndo()
          this.canRedo = this.history.canRedo()
        })
        this.graph.on('node:change:parent', ({ node }) => {
          if (node.hasParent()) node.setZIndex(node.parent.getZIndex() + 1)
        })
        this.graph.on('cell:mouseenter', () => {
          for (const port of container.querySelectorAll('.x6-port-body')) {
            port.style.visibility = 'visible'
          }
        })
        this.graph.on('cell:mouseleave', ({ cell }) => {
          for (const port of container.querySelectorAll('.x6-port-body')) {
            port.style.visibility = 'hidden'
          }
        })
        this.graph.bindKey('backspace', () => {
          const cells = this.graph.getSelectedCells()
          if (cells.length) this.graph.removeCells(cells)
        })
        this.graph.on('node:dblclick', ({ node }) => {
          if (node.data.parent) {
            this.$refs.groupForm.show(node)
          } else if (node.data.isText) {
            this.$refs.textForm.show(node)
          } else {
            this.$refs.sourceDrawer.show(node)
          }
        })
        this.graph.on('node:collapse', ({ node, e }) => {
          e.stopPropagation()
          node.toggleCollapse()
          const collapsed = node.isCollapsed()
          const collapse = (parent) => {
            const cells = parent.getChildren()
            if (cells) {
              cells.forEach((cell) => {
                if (collapsed) {
                  cell.hide()
                } else {
                  cell.show()
                }
                if (cell instanceof Group) {
                  if (!cell.isCollapsed()) {
                    collapse(cell)
                  }
                }
              })
            }
          }
          collapse(node)
        })
      }
    },
    initStencil () {
      this.stencil = new Addon.Stencil({
        title: '拓扑图',
        target: this.graph,
        stencilGraphWidth: 200,
        collapsable: true,
        groups: [
          { name: 'base', title: '基本图形', graphHeight: 64 },
          { name: 'host', title: '主机图标', graphHeight: 64 },
          { name: 'middleware', title: '中间件图标', graphHeight: 64 },
          { name: 'database', title: '数据库图标', graphHeight: 112 }
        ],
        layoutOptions: {
          columns: 4,
          columnWidth: 'auto',
          rowHeight: 'auto',
          resizeToFit: true
        },
        validateNode: (node) => {
          if (this.permission.update) {
            if (node.data.parent) {
              this.$refs.groupForm.show(node)
            } else if (node.data.sourceType) {
              this.$refs.sourceSelectForm.show(node)
            } else {
              const n = JSON.parse(JSON.stringify(node))
              const { x, y } = n.position
              n.position = { x, y }
              this.graph.addNode(n)
            }
          }
          return false
        }
      })
      this.stencil.load(getSourceNodeList(this.graph, 'base'), 'base')
      this.stencil.load(getSourceNodeList(this.graph, 'host'), 'host')
      this.stencil.load(getSourceNodeList(this.graph, 'middleware'), 'middleware')
      this.stencil.load(getSourceNodeList(this.graph, 'database'), 'database')
      document.getElementById('stencil').appendChild(this.stencil.container)
    },
    fetch () {
      getProjectTopology(this.$route.params.id).then((res) => {
        this.graph.fromJSON(res.data || {})
        this.graph.centerContent({ padding: { left: -200 } })
      })
    },
    save () {
      updateProject(this.$route.params.id, {
        topology: this.graph.toJSON()
      }).then((res) => {
        this.$message.success(res.message)
        this.graph.cleanHistory()
      })
    },
    click (key) {
      switch (key) {
        case 'save':
          return this.save()
        case 'undo':
          return this.history.undo()
        case 'redo':
          return this.history.redo()
        case 'clear':
          return this.graph.clearCells()
      }
    },
    goBack () {
      if (this.permission.update && this.canUndo) {
        this.$confirm({
          title: '系统提示',
          content: '当前更改尚未保存，是否要进行保存？',
          okText: '确认',
          cancelText: '取消',
          onOk: () => {
            this.save()
            this.$router.push({ name: 'SystemList' })
          },
          onCancel: () => {
            this.$router.push({ name: 'SystemList' })
          }
        })
      } else {
        this.$router.push({ name: 'SystemList' })
      }
    }
  }
}
</script>

<style lang="less">
#stencil {
  .x6-node {
    foreignObject {
      white-space: nowrap;
    }
  }
}
.groupTool {
  text-decoration: underline;
  cursor: pointer;
}
@keyframes topology-edge {
  to {
    stroke-dashoffset: -1000;
  }
}
</style>
