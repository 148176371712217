<template>
   <a-modal title="编辑文本" :visible="visible" :width="720" @ok="ok" @cancel="reset">
    <a-form-model
      ref="form"
      :label-col="{ span: 4, style: { textAlign: 'left' } }"
      :model="form"
      :rules="rules"
      :wrapper-col="{ span: 20 }"
    >
      <a-form-model-item label="文本" prop="text">
        <a-input v-model="form.text" :max-length="128" placeholder="请输入文本"></a-input>
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
export default {
  name: 'TextForm',
  data () {
    return {
      visible: false,
      form: {
        text: ''
      },
      rules: {
        text: [{ message: '请输入文本值', required: true, trigger: 'blur' }]
      },
      node: {}
    }
  },
  methods: {
    show (node) {
      this.node = node
      this.visible = true
      this.form.text = node.store.data.attrs.label.text
    },
    reset () {
      this.$refs.form.resetFields()
      this.visible = false
    },
    ok () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.node.attr('label/text', this.form.text)
          this.$emit('ok', this.node)
          this.reset()
        }
      })
    }
  }
}
</script>

<style>

</style>
